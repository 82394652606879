import React, { Component } from "react"

export class Footer extends Component {
    render() {
        return (
            <footer style={{ backgroundColor: `#8FBC8F`, padding: `3rem 1.45rem`, marginTop: `auto` }}>
            <p>48 Walton Road, East Molesey KT8 0DQ </p>©{""}
            {new Date().getFullYear()}, Built with nature and love
            {` `}
          </footer>
        )
    }
}