import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./contact.css"

const KEY = "AIzaSyDTZraCTb-STS-yZIYjt_WgIc8ObBFb68w"

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Contact" />
        <div className="contact-wrapper">
          <h1>Find Us</h1>
          <div className="contact-map-container">
            <iframe
              width="600"
              height="550"
              frameborder="0"
              style={{ border: `0` }}
              src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ-_FEP5kLdkgRxZ4zBnpIovw&key=${KEY}`}
              allowfullscreen
            />
          </div>
        </div>
        <div className="contact-address-container">
          <h3 className="contact-text">Address</h3>
          <p className="contact-text">Nature Health - Chinese Medical Centre</p>
          <p className="contact-text">48 Walton Road</p>
          <p className="contact-text">East Molesey</p>
          <p className="contact-text">Surrey</p>
          <p className="contact-text">KT8 0DQ</p>
          <p className="contact-text">020 8941 8666</p>
          <div className="contact-hours-container">
            <h3 className="contact-text">Opening Hours</h3>
            <p className="contact-text">Monday - Sunday</p>
            <p className="contact-text">9.30am - 8.00pm</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactPage
