import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

const ListLink = props => (
  <li className="nav-item">
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const Header = ({ siteTitle }) => (
  <div className="header-container">
    <Link className="header-logo-container" to="/">
      <h3 className="header-logo">{siteTitle}</h3>
    </Link>
    <ul className="nav-container">
      <ListLink to="/">Home</ListLink>
      <ListLink to="/treatments/">Price List</ListLink>
      <ListLink to="/contact/">Contact</ListLink>
    </ul>
    <p className="header-details">Accupuncture | Massage | Herbal Medicine | Cupping | Gua Sha </p>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
